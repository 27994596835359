/* ------------------------------------------------------------ *\
  Loader
\* ------------------------------------------------------------ */

.loader-wrapper {
  background-color: #fff;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.loader {
  background-position: center center;
  background-repeat: no-repeat;
  height: 200px;
  left: 50%;
  margin: -100px 0 0 -100px;
  position: absolute;
  top: 50%;
  width: 200px;
}

.loader-ellips {
  font-size: 20px;
  position: relative;
  width: 64px;
  margin: 100px auto 0;

  &__dot {
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 0.5em;
    position: absolute;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }
  &__dot:nth-child(1),
  &__dot:nth-child(2) {
    left: 0;
  }
  &__dot:nth-child(3) {
    left: 1.5em;
  }
  &__dot:nth-child(4) {
    left: 3em;
  }
  &__dot:nth-child(1) {
    animation-name: reveal;
  }
  &__dot:nth-child(2),
  &__dot:nth-child(3) {
    animation-name: slide;
  }
  &__dot:nth-child(4) {
    animation-name: reveal;
    animation-direction: reverse;
  }

  &__dot:nth-child(1) {
    background: #404040;
  }
  &__dot:nth-child(2) {
    background: #f36528;
  }
  &__dot:nth-child(3) {
    background: #404040;
  }
  &__dot:nth-child(4) {
    background: #404040;
  }
}

@keyframes reveal {
  from {
    transform: scale(0.001);
  }
  to {
    transform: scale(1);
  }
}
@keyframes slide {
  to {
    transform: translateX(1.5em)
  }
}