/* ------------------------------------------------------------ *\
  Wrapper
\* ------------------------------------------------------------ */

.wrapper {
  position: relative;

  .wrapper__overlay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .9);
    font-size: 25px;
    font-weight: 700;
    color: $primary-color;
  }
}

/* ------------------------------------------------------------ *\
  Container
\* ------------------------------------------------------------ */

.container {
  max-width: 80%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;

  &--small {
    max-width: 1200px
  }
}

/* ------------------------------------------------------------ *\
  Cols
\* ------------------------------------------------------------ */

.cols {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -30px;

  &--justify-center {
    justify-content: center;
  }

  .col {
    padding: 0 15px 30px;
  }
}

.col {
  flex: 1 1 100%;

  &--1of3 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  &--1of2 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  &--size-plan {
    max-width: 360px;
    flex: 0 0 360px;
  }
}

/* ------------------------------------------------------------ *\
  Box
\* ------------------------------------------------------------ */

.box {
  margin-bottom: 30px;
  padding: 30px 15px;
  background: $white;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: $text-base-color;

  &:last-child {
    margin-bottom: 0;
  }

  .box__title {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--small-spacing {
    padding: 10px 15px;
  }

  &--simple {
    padding: 10px;
    text-align: center;

    .alert {
      margin: 0 0 20px 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  &--centered {
    text-align: center;
  }
}

/* ------------------------------------------------------------ *\
  Matericl Icons
\* ------------------------------------------------------------ */

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
