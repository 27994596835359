/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

$font-family-sans-serif: Lato, sans-serif;
$font-family-serif: Times, 'Times New Roman', serif;

$font-size-base: 14px;
$line-height-base: 1.2;

$shell-max-width: 1190px;
$shell-gutter: 15px;

$responsive-xxs: 374px;
$responsive-xs: 767px;
$responsive-sm: 1023px;
$responsive-md: 1200px;
$responsive-lg: 1440px;

$mobile-small: '(max-width: #{$responsive-xxs}) ';
$mobile: '(max-width: #{$responsive-xs}) ';
$tablet-portrait: '(max-width: #{$responsive-sm}) ';
$small-desktop: '(max-width: #{$responsive-md}) ';
$large-desktop: '(max-width: #{$responsive-lg}) ';
$retina: '(min-resolution: 2dppx) ';

// Usage
// @media #{$small-desktop}

$white: #fff;
$black: #000;
$gray: #dcdcdc;

$text-base-color: #444446;

$primary-color: #f36528;
$secondary-color: #505D68;