/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
	min-width: 320px;
	background: $white;
	font-family: $font-family-sans-serif;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $text-base-color;
}

a {
	color: inherit;
	text-decoration: none;

	&:hover,
	&[href^='tel'] {
		text-decoration: none;
	}
}

p:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child {
  margin-bottom: 0;
}