/* ------------------------------------------------------------ *\
	Form Auth
\* ------------------------------------------------------------ */

.form-auth {
  max-width: 522px;
  padding: 50px 88px 98px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 7px 9px 125px 0px rgba(0, 0, 0, 0.27);

  .loader-suspense {
    margin-bottom: 0;
  }

  .link {
    font-weight: 600;
    color: #f36528;

    &:hover {
      text-decoration: underline;
    }
  }

  .field {
    padding: 15px 12px;
    border: 2px solid #abacab;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 900;
    color: #404040;

    &::placeholder {
      color: #b2b2b2;
    }
  }

  .btn {
    display: block;
    width: 100%;
    padding: 15px 12px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    color: #fff;

    &--facebook {
      font-size: 19px;
    }
  }

  .form__title {
    margin-bottom: 40px;
    font-size: 30px;
    text-align: center;
    color: #b2b2b2;
  }

  .form__row + .form__row {
    margin-top: 27px;
  }

  .form__actions {
    margin-top: 26px;
    margin-bottom: 26px;

    &:last-child {
      margin-bottom: 0;
    }

    .btn + .btn {
      margin-top: 10px;
    }
  }

  .form__outro {
    font-size: 17px;
    text-align: center;
  }
}

/* ------------------------------------------------------------ *\
  Form Auth Alt
\* ------------------------------------------------------------ */

.form-auth--alt {
  padding: 50px 67px 98px;

  .form__title {
    font-size: 26px;
  }
}