/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
  .section__group + .section__group {
    margin-top: 20px;
  }
}

/* ------------------------------------------------------------ *\
	Section Auth
\* ------------------------------------------------------------ */

.section--auth {
  padding: 50px 0;
}

/* ------------------------------------------------------------ *\
  Section Plans
\* ------------------------------------------------------------ */

.section-plans {
  text-align: center;
  color: $white;

  .section__head {
    padding: 60px 0 190px;
    margin-bottom: -120px;
    background: $primary-color;
    font-size: 17px;

    &-inner {
      max-width: 500px;
      margin: 0 auto;
    }
  }

  .section__title {
    margin-bottom: 20px;
    font-size: 40px;
  }
}

/* ------------------------------------------------------------ *\
  Section Profile
\* ------------------------------------------------------------ */

.section-profile {
  .section__title {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba($primary-color, .5);
    font-size: 25px;
    text-align: center;
    color: $primary-color;
  }

  .section__group {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* ------------------------------------------------------------ *\
  Section Message
\* ------------------------------------------------------------ */

.section-message {
  text-align: center;

  .section__inner {
    max-width: 600px;
    margin: 0 auto;
  }

  .section__icon i {
    font-size: 150px;
  }

  .section__title {
    margin-bottom: 30px;
    font-size: 35px;
  }

  .btn {
    font-size: 18px;
  }
}

/* ------------------------------------------------------------ *\
  Section Ad Preview
\* ------------------------------------------------------------ */

.section-ad-preview {
  .alert {
    max-width: 600px;
    padding: 30px 20px;
    margin: 20px auto;
    text-align: center;
  }

  .section-ad-preview__bar {
    padding: 23px 0;
    background: #252f40;

    .shell {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .section-ad-preview__title {
    font-size: 25px;
    font-weight: 900;
    color: #fff;
  }

  .section-ad-preview__body {
    padding: 80px 0;
  }
}